import React from "react"
import Clamp from "components/clamp"

export default () => (
  <>
    <Clamp inset>
      <h3>GDPR PRIVACY POLICY</h3>
      <h4>OUR COMMITMENT TO PRIVACY</h4>
      <p>
        Your privacy is important to GPI Capital L.P. and our affiliates
        (together, ‘our’, ‘us’, ‘we’ or ‘company’). To better protect your
        privacy, we are providing information explaining our online information
        practices.
      </p>
      <p>
        This Privacy Policy describes how we gather and use information for
        visitors of this website. Please read this Privacy Policy carefully.
      </p>
      <p>
        For the purposes of applicable EU laws, GPI Capital, L.P. will each be a
        data controller of any personal data collected by us. If you have any
        questions regarding our use of your personal data, or this Privacy
        Policy, please contact the company’s Legal Department at 1345 Avenue of
        the Americas, 32nd Floor, New York, NY 10105 or at legal@gpicap.com.
      </p>
      <p>
        We rely on various legal bases under applicable data protection
        legislation in order to process your personal information (e.g., name,
        title, address, email, date of birth and financial data, etc.),
        including our legitimate interests, contractual necessity and as
        required by law. We use the personal information we collect to operate
        our business and provide you with the services and products we offer and
        perform essential business operations. We do not collect any special
        categories of personal information about you (this includes details
        about your race or ethnicity, religious or philosophical beliefs, sex
        life, sexual orientation, political opinions, trade union membership,
        information about your health and genetic and biometric data). Nor do we
        collect any information about criminal convictions and offences.
      </p>
      <p>
        If we require your personal information due to a legal requirement or
        obligation or in order to perform a contract with you, we will make you
        aware of this at the time we collect your personal data, and the
        possible consequences of you failing to provide this personal data.
        Failure to provide this information means that we cannot provide our
        services or products to you. In this case, we may have to cancel a
        product or service you have with us but we will notify you if this is
        the case at the time.
      </p>
      <p>
        Further information about the legal bases under which we process your
        personal information is included in the section headed “Why we use your
        personal information”.
      </p>
      <p>
        You do not need to take any action as a result of this Privacy Policy,
        but you do have certain rights as described below in the section headed
        “Your rights”.
      </p>
      <h4>HOW WE OBTAIN YOUR PERSONAL INFORMATION</h4>
      <h5>Information you provide to us</h5>
      <p>
        We collect your personal information when you decide to interact with us
        and we only collect the information necessary to carry out our business
        for the purposes set out below. You can provide us with personal
        information via email, post and telephone.
      </p>
      <h5>
        Information provided by third parties or publicly available sources
      </h5>
      <p>
        We also process information in relation to companies that we are
        evaluating in connection with a potential investment in such companies.
        This personal information is obtained via third parties (i.e., the
        company in question).
      </p>
      <p>
        In addition, as part of any recruitment process, we collect and process
        personal data relating to job applicants. We may collect this
        information in a variety of ways. For example, data might be contained
        in application forms, CVs or resumes, obtained from your passport or
        other identity documents, or collected through interviews or other forms
        of assessment. We may also collect personal data about you from third
        parties, such as references supplied by former employers, information
        from employment background check providers and information from criminal
        records check or screenings.
      </p>
      <h4>WHY WE USE YOUR PERSONAL INFORMATION</h4>
      <p>
        To the extent that you provide us with any personal information, either
        through or in connection with this website (i.e., via telephone, email
        or post) or otherwise, we may use such information for the following
        purposes:
      </p>
      <table>
        <tr>
          <th style={{ width: `75%` }}>Purpose</th>
          <th style={{ width: `25%` }}>Legal Basis</th>
        </tr>
        <tr>
          <td>
            To provide you with the services, financing or financial products
            you have requested
          </td>
          <td>Contractual necessity</td>
        </tr>
        <tr>
          <td>To keep a record of your relationship with us</td>
          <td>Legitimate interest</td>
        </tr>
        <tr>
          <td>To verify your identity</td>
          <td>Legal requirement</td>
        </tr>
        <tr>
          <td>
            To conduct due diligence activities in connection with an actual or
            prospective corporate transaction or investment with respect to
            which we are party to
          </td>
          <td>Legitimate interest</td>
        </tr>
        <tr>
          <td>Fraud and abuse prevention</td>
          <td>Legitimate interest</td>
        </tr>
        <tr>
          <td>
            Litigation management and conducting internal audits and
            investigations
          </td>
          <td>Legitimate interest</td>
        </tr>
        <tr>
          <td>To administer and protect our business and this website</td>
          <td>Legitimate interest</td>
        </tr>
        <tr>
          <td>
            To make suggestions and recommendations to you about goods or
            services that may be of interest to you
          </td>
          <td>Legitimate interest</td>
        </tr>
        <tr>
          <td>
            To evaluate whether to enter into an employment or similar
            relationship with you
          </td>
          <td>Legitimate interest</td>
        </tr>
      </table>
      <p>
        No automated decision-making, including profiling, is used when
        processing your personal information.
      </p>
      <h4>MARKETING</h4>
      <p>
        We will send you direct marketing communications where we have obtained
        your prior consent to keep you informed of our products and services in
        which you might be interested, or based on our legitimate business
        interests where we have an existing relationship with you and we wish to
        contact you about similar products or services in which you may be
        interested. You may opt-in to certain kinds of marketing, and all forms
        of marketing at any time, by emailing legal@gpicap.com. You can also
        click on the “opt-out”/unsubscribe link provided in all our marketing
        emails.
      </p>
      <h4>DISCLOSURE AND TRANSFER OF PERSONAL INFORMATION</h4>
      <p>
        Unfortunately, the transmission of information and data via the Internet
        is not completely secure. Although we do our best to protect your
        personal data, we cannot guarantee the security of any information or
        data transmitted to or through our website; any transmission of
        information or data by you to or through this website is at your sole
        risk.
      </p>
      <p>
        Your personal information will be shared with and processed by our
        affiliates and certain service providers as necessary to fulfil the
        purposes set out in this policy, including professional advisors,
        administrators, recruitment firms, consultants and data hosting
        providers. We make sure anyone who provides a service to, or for us,
        enters into an agreement with us and meets our standards for data
        security. To the extent your personal information is transferred to
        countries outside of the EEA, such transfers will only be made in
        accordance with applicable data privacy laws. For further information
        about the safeguards/derogations used, please contact legal@gpicap.com.
      </p>
      <p>
        To the extent any personal information is transferred from within the
        EEA to GPI Capital, L.P. or an affiliate outside the EEA, this transfer
        is legitimised and your personal information is adequately protected
        under the terms of the EU controller-to-controller model contract
        clauses.
      </p>
      <p>
        We reserve the right to disclose your personal information as required
        by law, or when we believe that disclosure is necessary to protect our
        rights and/or comply with a judicial proceeding, court order, request
        from a regulator, national security, for the purposes of public
        importance or any other legal or investigatory process involving us.
        Should we, or any of our affiliated entities, be the subject of a
        takeover, divestment or acquisition we may disclose your personal
        information to the new owner of the relevant business and their advisors
        on the basis of our legitimate interest.
      </p>
      <h4>SECURITY AND RETENTION OF PERSONAL INFORMATION</h4>
      <p>
        We are committed to protecting the personal information you entrust to
        us. We adopt robust and appropriate technologies and policies, so the
        information we have about you is protected to the extent possible from
        unauthorized access and improper use. In addition, we limit access to
        your personal information to those employees, agents, contractors and
        other third parties who have a business need to know. They will only
        process your personal data on our instructions and they are subject to a
        duty of confidentiality.
      </p>
      <p>
        We have put in place procedures to deal with any suspected personal
        information breach and will notify you and any applicable regulator of a
        breach where we are legally required to do so.
      </p>
      <p>
        We will keep your personal information only for as long as is reasonably
        necessary for the purposes set out in this Privacy Policy, unless a
        longer retention period is required by law. We will not keep more
        information than we need for those purposes. For further information
        about how long we will keep your personal information, please contact
        legal@gpicap.com.
      </p>
      <h4>CAPACITY</h4>
      <p>
        This website is only intended for individuals who are at least 13 years
        of age. We do not knowingly encourage or solicit visitors to this
        website who are under the age of 13 or knowingly collect personal
        information from anyone under the age of 13 without parental consent. If
        we learn we have collected or received personal information from an
        individual under the age of 13, we will delete that information.
      </p>
      <h4>YOUR RIGHTS</h4>
      <p>
        You have the right to access the personal information we hold about you,
        and there are a number of ways you can control the way in which and what
        information we store and process about you. To exercise these rights and
        controls, please contact legal@gpicap.com.
      </p>
      <ul>
        <li>
          <strong>Access:</strong> You have the right to ask for a copy of the personal
          information that we hold about you free of charge, however we may
          charge a ‘reasonable fee’, if we think that your request is excessive,
          to help us cover the costs of locating the information you have
          requested.
        </li>
        <li>
          <strong>Correction:</strong> You may notify us of changes to your personal information
          if it is inaccurate or it needs to be updated.
        </li>
        <li>
          <strong>Deletion:</strong> If you think that we shouldn’t be holding or processing your
          personal information any more, you may request that we delete it.
          Please note that this may not always be possible due to legal
          obligations.
        </li>
        <li>
          <strong>Restrictions on use:</strong> You may request that we stop processing your
          personal information (other than storing it), if: (i) you contest the
          accuracy of it (until the accuracy is verified); (ii) you believe the
          processing is against the law; (iii) you believe that we no longer
          need your data for the purposes for which it was collected, but you
          still need your data to establish or defend a legal claim; or (iv) you
          object to the processing, and we are verifying whether our legitimate
          grounds to process your personal information, override your own
          rights.
        </li>
        <li>
          <strong>Object:</strong> You have the right to object to processing, including: (i) for
          direct marketing; (ii) for research or statistical purposes; or (iii)
          where processing is based on legitimate interests.
        </li>
        <li>
          <strong>Portability:</strong> If you wish to transfer your personal information to
          another organisation (and certain conditions are satisfied), you may
          ask us to do so, and we will send it directly if we have the technical
          means.
        </li>
        <li>
          <strong>Withdrawal of consent:</strong> If you previously gave us your consent (by a
          clear affirmative action) to allow us to process your personal
          information for a particular purpose, but you no longer wish to
          consent to us doing so, you can contact us to let us know that you
          withdraw that consent.
        </li>
      </ul>
      <p>
        We may need to request specific information from you to help us confirm
        your identity and ensure your right to access your personal information
        (or to exercise any of your other rights). This is a security measure to
        ensure that personal data is not disclosed to any person who has no
        right to receive it. We may also contact you to ask you for further
        information in relation to your request to speed up our response.
      </p>
      <h4>NOTIFICATION OF CHANGES</h4>
      <p>
        We reserve the right to amend this Privacy Policy from time to time by
        updating this Privacy Policy. If we decide to change our Privacy Policy,
        we will post those changes so our users are always aware of what
        information we collect, how we use it, and under what circumstances, if
        any, we disclose it. If at any point we decide to collect personal
        information or use any collected information in a manner different from
        that stated at the time it was collected, we will notify applicable
        users. We will use information only in accordance with the Privacy
        Policy under which the information was collected.
      </p>
      <h4>CONTACT US</h4>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact legal@gpicap.com.
      </p>
      <h4>COMPLAINTS</h4>
      <p>
        Should you wish to lodge a complaint with regards to how your personal
        information has been processed by us, please contact your local
        supervisory authority. In the UK, this is the Information Commissioner’s
        Office – https://ico.org.uk/global/contact-us
      </p>
    </Clamp>
  </>
)
